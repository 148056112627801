import { Collapse } from 'bootstrap';
import { withPlugins, eventBus, domEvents, device } from '@spon/plugins';
import Headroom from 'headroom.js';
import toggle from '@/ui/toggle';

function Header({ node, name, plugins: { addEvents, device } }) {
	if (!node) return;
	/*
	 *		Variables
	 */
	const breakpoint = 1024;
	const minDesktopBackground = 367;
	const navBackground = node.querySelector('[data-background]');
	// const allSubNav = [...node.querySelectorAll('.c-nav__subwrapper')];
	let activeSubNav = false;

	const headroom = new Headroom(node, {
		// offset: node.clientHeight * 1.25 || 0,
		tolerance: {
			up: 5,
			down: 0,
		},
	});

	const nav = toggle({
		button: node.querySelector('[data-menu-opener]'),
		name,
		activeClass: 'is-active',
	});

	let windowTop = 0;
	const lock = {
		capture() {
			windowTop = window.scrollY;
			document.body.style.position = 'fixed';
			document.body.style.top = `${windowTop * -1}px`;
			document.body.style.overflow = 'hidden';
			document.body.style.width = '100vw';

			headroom.freeze();
		},
		release() {
			document.body.style.position = '';
			document.body.style.top = '';
			document.body.style.overflow = '';
			document.body.style.width = '';
			if (windowTop) window.scroll(0, windowTop);

			setTimeout(() => headroom.unfreeze(), 1);
		},
	};

	const navSubmenus = [...node.querySelectorAll('.collapse')];
	let navSubmenuAccordions = [];
	const collapseList = navSubmenus.map(collapseEl => {
		const acc = new Collapse(collapseEl, {
			parent: node,
			toggle: false,
		});
		navSubmenuAccordions.push(acc);

		collapseEl.addEventListener('show.bs.collapse', () => {
			activeSubNav = collapseEl;
			const navHeight = node.offsetHeight + collapseEl.scrollHeight;
			node.classList.add('dropdown-open');
			navBackground.style.setProperty(
				'--height',
				`${Math.max(minDesktopBackground, navHeight)}px`
			);
		});

		collapseEl.addEventListener('shown.bs.collapse', () => {
			// If input
			const input = collapseEl.querySelector('input');
			if (input) input.focus();
		});

		collapseEl.addEventListener('hide.bs.collapse', () => {
			if (collapseEl === activeSubNav) {
				activeSubNav = false;
				node.classList.remove('dropdown-open');
				navBackground.style.setProperty('--height', '5rem');
			}
		});

		return acc;
	});

	/*
	 *		Functions
	 */

	function closeAllAccordions() {
		navSubmenuAccordions.forEach(a => {
			a.hide();
		});
		activeSubNav = false;
		node.classList.remove('dropdown-open');
		navBackground.style.setProperty('--height', '5rem');
	}

	function onTopClick(event) {
		// If mobile; prevent Default
		if (window.innerWidth < breakpoint) {
			event.preventDefault();
		}
	}

	function onBackClick() {
		closeAllAccordions();
	}

	function onCloseClick() {
		activeSubNav = false;
		nav.close();
	}

	/*
	 *		Init
	 */
	nav.init();
	headroom.init();

	/*
	 *		Events
	 */
	addEvents({
		'click [data-nav-top]': onTopClick,
		'click [data-close]': onCloseClick,
		'click [data-back]': onBackClick,
	});

	addEvents(document.body, {
		click: ({ target }) => {
			if (
				target.id === 'header' ||
				target.closest('#header') ||
				window.innerWidth < breakpoint
			)
				return;
			// Close the accordions
			closeAllAccordions();
		},
		keydown: ({ key }) => {
			if (key === 'Escape') {
				if (nav.isOpen) {
					nav.close();
				}
				closeAllAccordions();
			}
		},
	});

	// Housekeeping; mobile tray
	nav.on(`open:${name}`, ({ target }) => {
		node.classList.add('nav-open');
		target.classList.add('is-open');
		lock.capture();
	});

	nav.on(`close:${name}`, ({ target }) => {
		node.classList.remove('nav-open');
		target.classList.remove('is-open');
		lock.release();

		// Close all Sub menu Navs
		navSubmenuAccordions.forEach(a => {
			a.hide();
		});
	});

	device.at('(min-width: 768px)', {
		on: () => {
			nav.close();
		},
		off: () => {},
	});

	eventBus.on('scroll-lock', () => lock.capture());
	eventBus.on('scroll-unlock', () => lock.release());

	device.resize(() => {
		// closeAllAccordions();

		if (activeSubNav) {
			const navHeight = node.offsetHeight + activeSubNav.scrollHeight;
			node.classList.add('dropdown-open');
			navBackground.style.setProperty(
				'--height',
				`${Math.max(minDesktopBackground, navHeight)}px`
			);
		}
	});

	return () => {
		collapseList.forEach(item => {
			item.dispose();
		});
	};
}

export default withPlugins(domEvents, device)(Header);
